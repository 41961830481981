import { Injectable } from '@angular/core';
import { EnlacesRedireccionMaestrosStore, KeyEnlacesRedireccionesMaestros } from './enlaces-redireccion-maestros.store';
import { FormGroup } from '@angular/forms';
import { EnlacesRedireccionCargaStore, KeyEnlacesRedireccionesCarga } from './enlaces-redireccion-carga.store';

export interface ElementsEnlace {
	title: string;
	isVisible?: boolean;
	url?: string;
	isDisabled: boolean;
	urlFunction?: (ids: unknown[]) => string;
}

@Injectable({ providedIn: 'root' })
export class RedirectUtilityService {
	constructor(private readonly _enlacesRedireccionCargaStore: EnlacesRedireccionCargaStore, private readonly _enlacesRedireccionMaestrosStore: EnlacesRedireccionMaestrosStore) {}

	onRedirectMaestros(key: KeyEnlacesRedireccionesMaestros): ElementsEnlace {
		return this._enlacesRedireccionMaestrosStore.listEnlaceRegireccion[key];
	}

	onRedirectCarga(key: KeyEnlacesRedireccionesCarga): ElementsEnlace {
		return this._enlacesRedireccionCargaStore.listEnlaceRegireccion[key];
	}

	getIdRedirect(form: FormGroup, control: string, key: string): string {
		if (form.get(control)) {
			const controlValue = form.get(control)?.value;
			if (typeof controlValue === 'number') {
				return controlValue as unknown as string;
			} else if (isNaN(controlValue) && controlValue && key in controlValue) {
				return controlValue[key] as string;
			}
		}
		return '';
	}

	requiredNotNull(form: FormGroup, control: string): boolean {
		if (form.get(control)?.valid) return true;
		else return false;
	}
}
